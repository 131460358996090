import { ShippingMethod, TaxIdType } from '@rediredi/types'

import { IStorefrontOrder } from './order'
import type { CheckoutStore, DeliveryAddress } from '~/types/checkout'

export const useCheckoutStore = defineStore('checkout', {
  state: (): CheckoutStore => {
    return {
      notes: '',
      coupon: {
        code: '',
        isApplied: false,
        isLoading: false,
      },
      personalInfo: {
        isOpen: true,
        isDisabled: false,
        isComplete: false,
        form: {
          name: '',
          email: '',
          taxId: '',
          taxIdType: TaxIdType.CPF,
          phone: {
            dialCode: '',
            countryCode: '',
            number: '',
          },
        },
      },
      deliveryMethod: {
        isOpen: true,
        isDisabled: true,
        isComplete: false,
        form: {
          isZipCodeLoading: false,
          deliveryMethod: undefined,
          zipCode: '',
          addressLine1: '',
          streetNumber: '',
          addressComplement: '',
          neighborhood: '',
          city: '',
          stateOrRegionCode: '',
          stateOrRegion: '',
          municipality: '',
        },
      },
      payment: {
        isOpen: true,
        isDisabled: true,
        isComplete: false,
        form: {
          paymentMethod: undefined,
        },
      },
      totals: {},
    }
  },

  getters: {
    isPickup: (state) => {
      return state.deliveryMethod.form.deliveryMethod === ShippingMethod.Pickup
    },

    isDelivery: (state) => {
      return state.deliveryMethod.form.deliveryMethod === ShippingMethod.Delivery
    },

    isDigital: (state) => {
      return state.deliveryMethod.form.deliveryMethod === ShippingMethod.Digital
    },

    isRoom: (state) => {
      return state.deliveryMethod.form.deliveryMethod === ShippingMethod.Room
    },

    isToBeArranged: (state) => {
      return state.deliveryMethod.form.deliveryMethod === ShippingMethod.ToBeArranged
    },

    isTaxIdRequired: () => {
      const globalStore = useGlobalStore()

      return globalStore.store.checkoutSettings?.isTaxIdRequired
    },

    isDeliveryMethodRequired: () => {
      const globalStore = useGlobalStore()

      return globalStore.store.checkoutSettings?.isDeliveryAddressRequired
    },

    isPaymentMethodRequired: () => {
      const globalStore = useGlobalStore()

      return globalStore.store.checkoutSettings?.isPaymentMethodRequired
    },
  },

  actions: {
    openAllSections() {
      this.personalInfo.isOpen = true
      this.deliveryMethod.isOpen = true
      this.payment.isOpen = true
    },

    enableAllSections() {
      this.personalInfo.isDisabled = false
      this.deliveryMethod.isDisabled = false
      this.payment.isDisabled = false
    },

    async populateAddress(zipCode: string) {
      const currentStore = useCurrentStore()
      try {
        this.deliveryMethod.form.isZipCodeLoading = true

        const { data, error } = await useApiFetch<DeliveryAddress>(
          `/channel-storefront/addresses/zip-code/${zipCode}?country=${currentStore.countryCode}`,
        )

        if (error.value) throw error.value
        if (!data.value) throw new Error('Empty response')

        this.deliveryMethod.form.addressLine1 = data.value.addressLine1
        this.deliveryMethod.form.city = data.value.city
        this.deliveryMethod.form.neighborhood = data.value.neighborhood
        this.deliveryMethod.form.stateOrRegionCode = data.value.stateOrRegionCode
        this.deliveryMethod.form.stateOrRegion = data.value.stateOrRegion

        this.deliveryMethod.form.municipality = data.value.municipality
      } finally {
        this.deliveryMethod.form.isZipCodeLoading = false
      }
    },

    async placeOrder() {
      const cartStore = useCartStore()
      const itemsStore = useItemsStore()
      const globalStore = useGlobalStore()
      let error: any

      const shipment =
        this.isPickup || this.isDigital || this.isRoom || this.isToBeArranged
          ? {
              shipmentType: this.deliveryMethod.form.deliveryMethod,
            }
          : {
              shipmentType: this.deliveryMethod.form.deliveryMethod ?? ShippingMethod.ToBeArranged,
              receiverName: this.personalInfo.form.name,
              receiverAddress: {
                addressLine1: this.deliveryMethod.form.addressLine1,
                streetNumber: this.deliveryMethod.form.streetNumber,
                neighborhood: this.deliveryMethod.form.neighborhood,
                city: this.deliveryMethod.form.city,
                stateOrRegion: this.deliveryMethod.form.stateOrRegion,
                zipCode: this.deliveryMethod.form.zipCode,
                municipality: this.deliveryMethod.form.municipality,
                addressLine2: this.deliveryMethod.form.addressComplement,
              },
            }

      const payload: Record<string, any> = {
        storeId: globalStore.store.id,
        buyerNotes: this.notes || undefined,

        items: cartStore.cart.items.map((item) => ({
          itemId: item.id,
          quantity: item.quantity,
          price: item.price,
          variantId: item.variantId,
        })),

        buyer: {
          name: this.personalInfo.form.name,
          phone: {
            number: this.personalInfo.form.phone.number,
            countryCode: this.personalInfo.form.phone.dialCode,
          },
          isBillingAddressTheShippingAddress: false,
        },
      }

      if (this.personalInfo.form.email) {
        payload.buyer.email = this.personalInfo.form.email
      }

      payload.items = payload.items.map((i: CartItem) => {
        if (!i.price.amount) {
          return {
            ...i,
            price: undefined,
          }
        }

        return i
      })

      payload.shipment = shipment

      if (this.isDeliveryMethodRequired && this.isDelivery) {
        payload.buyer.isBillingAddressTheShippingAddress = true
      } else {
        payload.buyer.isBillingAddressTheShippingAddress = false
      }

      if (this.isPaymentMethodRequired) {
        payload.payment = {
          type: this.payment.form.paymentMethod,
        }
      }

      if (this.isTaxIdRequired) {
        payload.buyer.taxId = this.personalInfo.form.taxId
      }

      if (this.coupon.isApplied) {
        payload.promotion = {
          code: this.coupon.code,
        }
      }

      if (globalStore.store.salesperson) {
        payload.salespersonId = globalStore.store.salesperson.id
      }

      if (!payload.payment?.type) {
        delete payload.payment
      }

      const { data } = await useApiFetch<IStorefrontOrder>('/channel-storefront/orders', {
        method: 'POST',
        body: payload,
        headers: {},
        onResponseError: (e) => {
          error = e.response._data
          throw e.response._data
        },
      })

      if (error) {
        throw error
      }

      if (!data.value) {
        throw new Error('Empty order response')
      }

      cartStore.emptyCart()
      itemsStore.clearOnSearch()
      this.removeCoupon()
      return data.value
    },

    openNextSection(): [id: string, fn: () => void] {
      if (!this.personalInfo.isComplete) {
        return [
          'personal-info-form',
          () => {
            this.personalInfo.isDisabled = false
            this.personalInfo.isOpen = true
          },
        ]
      }

      if (!this.deliveryMethod.isComplete && this.isDeliveryMethodRequired) {
        return [
          'delivery-method-form',
          () => {
            this.deliveryMethod.isDisabled = false
            this.deliveryMethod.isOpen = true
          },
        ]
      }

      if (!this.payment.isComplete && this.isPaymentMethodRequired) {
        return [
          'payment-form',
          () => {
            this.payment.isDisabled = false
            this.payment.isOpen = true
          },
        ]
      }

      return ['', () => {}]
    },

    loadInfoFromStorage() {
      const { userInfoStorage } = useUserInfoStorage()

      this.personalInfo.form = {
        ...userInfoStorage.value.personal,
        phone: { ...userInfoStorage.value.personal.phone },
      }

      this.deliveryMethod.form = {
        ...this.deliveryMethod.form,
        ...userInfoStorage.value.delivery,
      }

      this.notes = userInfoStorage.value.notes

      if (this.deliveryMethod.form.zipCode) {
        this.deliveryMethod.isDisabled = false
      }
    },

    applyCoupon() {
      if (!this.coupon.code || this.coupon.isApplied) return

      this.coupon.isApplied = true
    },

    removeCoupon() {
      this.coupon.code = ''
      this.coupon.isApplied = false
    },
  },
})
