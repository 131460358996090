import { Currency, IItem, IMoney } from '@rediredi/types'
import type { CartItem } from '~/types/cart'

export interface MetaEventData {
  content_category?: string
  content_ids?: Array<string | number>
  content_name?: string
  content_type?: string
  contents?: [
    {
      id: string
      quantity: number
    },
  ]
  currency?: string
  num_items?: number
  predicted_ltv?: number
  search_string?: string
  status?: boolean
  value?: number
}

export const itemToAnalytics = (item: IItem) => {
  return {
    item_id: item.id,
    item_name: item.baseVariant.title,
    item_brand: item.brand,
    item_category: item.category?.name,
    price: item.baseVariant.price?.amount || 0,
    currency: item.baseVariant.price?.currency || Currency.BRL,
    quantity: 1,
  }
}

const cartItems = (items: CartItem[]) => {
  return items.map((i) => ({
    item_id: i.id,
    item_name: i.title,
    price: i.price?.amount || 0,
    quantity: i.quantity,
    currency: i.price?.currency || Currency.BRL,
  }))
}

const cartItemBaseEvent = (item: CartItem, items: CartItem[]) => {
  return {
    currency: item.price?.currency || Currency.BRL,
    value: item.price?.amount || 0,
    items: cartItems(items),
  }
}

export const getAddToCartEventPayload = (item: CartItem, items: CartItem[]) => {
  return cartItemBaseEvent(item, items)
}

export const getRemoveFromCartEventPayload = (item: CartItem, items: CartItem[]) => {
  return cartItemBaseEvent(item, items)
}

export const getViewCartEventPayload = (items: CartItem[], total: IMoney) => {
  return {
    value: total.amount,
    currency: total.currency,
    items: cartItems(items),
  }
}

export const getPurchaseEventPayload = (order: IStorefrontOrder) => {
  return {
    currency: order.itemPriceTotal?.currency || Currency.BRL,
    transaction_id: order.id,
    value: order.orderTotal?.amount || order.itemPriceTotal?.amount || 0,
    items: order.items.map((item) => ({
      item_id: item.id,
      item_name: item.title,
      price: item.itemPrice?.amount,
      currency: item.itemPrice?.currency,
      quantity: item.quantityOrdered,
    })),
  }
}

const metaAttributes = {
  _contentIds: (items: { id: string }[]) => items.map((i) => i.id),
  _contents: (items: CartItem[]) => items.map((i) => ({ id: i.id, quantity: i.quantity })),
  _currency: () => {
    const globalStore = useGlobalStore()
    return globalStore.storeCurrency.toString()
  },
  _value: () => {
    const cart = useCartStore()

    return cart.totalV2.amount
  },
}

export const MetaEventsPayloads = {
  addPaymentInfo: () => {
    const cartStore = useCartStore()

    return {
      content_ids: metaAttributes._contentIds(cartStore.cart.items),
      contents: metaAttributes._contents(cartStore.cart.items),
      currency: metaAttributes._currency(),
      value: metaAttributes._value(),
    } as Pick<MetaEventData, 'content_ids' | 'contents' | 'currency' | 'value'>
  },

  addToCart: (item: CartItem) => {
    const globalStore = useGlobalStore()

    return {
      content_type: 'product',
      content_ids: [item.id],
      contents: [{ id: item.id, quantity: item.quantity }],
      currency: item.price?.currency || globalStore.storeCurrency,
      value: item.price?.amount || 0,
    } as Pick<MetaEventData, 'content_type' | 'content_ids' | 'contents' | 'currency' | 'value'>
  },

  initiateCheckout: () => {
    const cartStore = useCartStore()

    return {
      content_ids: metaAttributes._contentIds(cartStore.cart.items),
      contents: metaAttributes._contents(cartStore.cart.items),
      currency: metaAttributes._currency(),
      value: metaAttributes._value(),
      num_items: cartStore.size,
    } as Pick<MetaEventData, 'content_ids' | 'contents' | 'currency' | 'value' | 'num_items'>
  },

  lead: () => {
    return {
      currency: metaAttributes._currency(),
      value: metaAttributes._value(),
    } as Pick<MetaEventData, 'currency' | 'value'>
  },

  purchase: (order: IStorefrontOrder) => {
    return {
      content_type: 'product',
      content_ids: metaAttributes._contentIds(order.items),
      contents: order.items.map((item) => ({ id: item.id, quantity: item.quantityOrdered })),
      currency: order.orderTotal?.currency,
      value: order.orderTotal?.amount,
      num_items: order.numberOfItems,
    } as Pick<
      MetaEventData,
      'content_type' | 'content_ids' | 'contents' | 'currency' | 'value' | 'num_items'
    >
  },

  viewContent: (item: IStorefrontItem) => {
    return {
      content_ids: [item.id],
      content_type: 'product',
      contents: [{ id: item.id, quantity: 1 }],
      currency: item.baseVariant.price?.currency,
      value: item.baseVariant.price?.amount,
    } as Pick<MetaEventData, 'content_ids' | 'content_type' | 'contents' | 'currency' | 'value'>
  },
}
